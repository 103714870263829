import React from "react";

import { ApiEngine } from "api-engine";
import { ApiContext } from "../../domains/api/contexts/ApiContext";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import NewsPageContent from "./NewsPageContent";

interface NewsProps {
}

interface NewsState {
}

export default class NewsPage extends React.Component<NewsProps, NewsState> {

    

    render() {
        return <div className={"news-page"}>
            <ApiProvider>
                <ApiContext.Consumer>
                    {
                        ({api, user}) => {
                            return <NewsPageContent api={api!!} />
                        }
                    }
                </ApiContext.Consumer>
            </ApiProvider>
    
        </div>;
    }
}