import React from "react";
import ApiProvider from "../../../domains/api/providers/ApiProvider";
import { ApiContext } from "../../../domains/api/contexts/ApiContext";
import NewsPageContent from "../../news/NewsPageContent";
import EducationPageContent from "./EducationPageContent";

interface EducationPageProps {
    
}

interface EducationPageState {  

}

export default class Education extends React.Component<EducationPageProps, EducationPageState> {
    render() {
        const me = this;
        return <ApiProvider>
        <ApiContext.Consumer>
            {
                ({api, user}) => {
                    if (!user) return <h3>Вы не авторизованы</h3>;
                    return <EducationPageContent api={api!!} />
                }
            }
        </ApiContext.Consumer>
    </ApiProvider>
    }
}