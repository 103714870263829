import React from "react";
import "./style/message-container-style.scss";

export default class MessageContainer extends React.Component<any, any> {
    render() {
        return <div className={"message-container"}>
            <div className={"first-line"}>
                <p className={"author"}>{this.props.message.author}</p>
                <p className={"created-at"}>{this.props.message.createdAt}</p>
            </div>
            <div className={"second-line"}>
                <p className={"content"}>{this.props.message.content}</p>
            </div>
        </div>
    }
}   