import React from "react";
import { ApiContext } from "../../domains/api/contexts/ApiContext";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import EmployeesPageContent from "./EmployeesPageContent";

interface EmployeesPageProps {

}

interface EmployeesPageState {

}

export default class EmployeesPage extends React.Component<EmployeesPageProps, EmployeesPageState> {
    render() {
        const me = this;
        return <ApiProvider>
        <ApiContext.Consumer>
            {
                ({api, user}) => {
                    if (!user) return <h3>Вы не авторизованы</h3>;
                    return <EmployeesPageContent api={api!!} />
                }
            }
        </ApiContext.Consumer>
    </ApiProvider>
    }
}   
