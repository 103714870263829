import React from "react";
import {ApiEngine} from "api-engine";
import "./style/employees.scss";

interface EmployeesPageContentProps {
    api: ApiEngine,
    hideTradingPointsChooser?: boolean,
    tradingPoint?: any
}

interface EmployeesPageContentState {
    employees: any[];
    shownEmployees: any[];
    companies: any[];
    shownCompany: any;
}

function uniq(array: any[]) {
    return array.filter((value, index) => array.map((v) => v.id).indexOf(value.id) === index);
}

export default class EmployeesPageContent extends React.Component<EmployeesPageContentProps, EmployeesPageContentState> {

    constructor(props: EmployeesPageContentProps) {
        super(props);
        this.state = {
            employees: [],
            companies: [],
            shownCompany: this.props.tradingPoint,
            shownEmployees: []
        }

        this.getEmployees = this.getEmployees.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getEmployees();
    }

    getEmployees() {
        const me = this;
        me.props.api.asyncFetch("/api/employees/", {})
            .then((response: any) => {
                me.setState({employees: response.employees, shownEmployees: []}, () => {
                    let shownEmployees = me.state.employees;
                    if (me.props.tradingPoint) {
                        shownEmployees = shownEmployees.filter((employee: any) => employee.company.id === me.props.tradingPoint.id);
                    }
                    me.setState({shownEmployees: shownEmployees}, () => {
                        let companies = me.state.shownEmployees.map((employee: any) => employee.company);
                        me.setState({companies: uniq(companies)});
                    });
                });
            });
    }

    render() {
        const me = this;
        return <div className="employees-page">
            {!me.props.hideTradingPointsChooser &&
                <div className="trading-points-chooser">
                    <h1>Сотрудники ({me.state.employees.length})</h1>
                </div>
            }
            <div className="employees-page-content">
                {!me.props.hideTradingPointsChooser &&
                    <div className="companies-list">
                        {me.state.companies.map((company: any) => {
                            return <p style={{
                                cursor: "pointer",
                                backgroundColor: me.state.shownCompany?.id === company.id ? "var(--green-500)" : "transparent"
                            }} onClick={() => me.setState({
                                shownCompany: company,
                                shownEmployees: me.state.employees.filter((employee: any) => employee.company.id === company.id)
                            })}>{company.title}</p>
                        })}
                    </div>
                }
                <div className="employees-list">
                    {!me.props.hideTradingPointsChooser && <h2>{me.state.shownCompany?.title}</h2>}
                    {me.state.shownEmployees.map((employee: any) => {
                        return <div className="employee-card">
                            <h3>{employee.last_name} {employee.first_name} {employee.middle_name}</h3>
                            <h4>{employee.company?.title}</h4>
                        </div>
                    })}
                </div>
            </div>
        </div>;
    }
}