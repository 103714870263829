import React from "react";
import "./style/comments.scss";
import { ApiEngine } from "api-engine";

interface CommentsProps {
    news: any;
    api: ApiEngine;
}

interface CommentsState {
    comments: any[];
    commentText: string;
    showComments: boolean;
}

export default class Comments extends React.Component<CommentsProps, CommentsState>{
    constructor(props: CommentsProps) {
        super(props);
        this.state = {
            comments: [],
            commentText: "",
            showComments: false
        };
        this.sendComment = this.sendComment.bind(this);
        this.getComments = this.getComments.bind(this);
    }

    componentDidMount() {
        this.getComments();
    }

    getComments(recurse: boolean = false) {
        const me = this;
        this.props.api.asyncFetch(`/api/news-articles/${this.props.news.id}/comments`, {}).then((comments: any) => {
            if (comments.comments) {
                me.setState({comments: comments.comments}, () => {
                    if (recurse) {
                        setTimeout(() => {
                            me.getComments(true);
                        }, 60_000);
                    }
                });
            }
        });
    }

    sendComment() {
        const me = this;
        const data = {
            content: this.state.commentText
        };
        this.props.api.asyncFetchWithoutQueing(`/api/news-articles/${this.props.news.id}/comments`, {
            method: "POST",
            body: JSON.stringify(data)
        }).then((comment: any) => {
            me.setState({commentText: ""}, me.getComments);
        });
    }

    render() {
        const me = this;

        return <div className="comments">
            <div className="comments-header" onClick={() => me.setState({showComments: !me.state.showComments})}>
                <h4>Комментарии ({me.state.comments.length})</h4>
                { me.state.comments.length > 0 && <span  >{me.state.showComments ? "Скрыть" : "Показать"}</span>}
            </div>
            <div className="comments-input">
                <textarea 
                    value={this.state.commentText}
                    onChange={(e) => this.setState({commentText: e.target.value})}
                    placeholder="Введите комментарий..."/>
                { me.state.commentText.length > 0 && <button onClick={this.sendComment}>
                    <span>Отправить</span>
                    <i className="fas fa-paper-plane"></i>
                </button>}
            </div>
            {me.state.showComments && <div className="comments-list">
                {me.state.comments.map((comment: any) => (
                    <div className="comment-container" key={comment.id}>
                        <p className="comment-author">{comment.author}</p>
                        <p className="comment-text">{comment.content}</p>
                    </div>
                ))}
            </div>}
        </div>
        ;
    }

    
}