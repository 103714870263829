import React from "react";
import './style/news.scss';
import StorySquare from "../story_square/common/models/StorySquare";
import StorySquareComponent from "../story_square/common/StorySquareComponent";
import StorySquareSkeleton from "../story_square/skeleton/StorySquareSkeleton";
import {ApiEngine} from "api-engine";
import ModalWindow from "../../../modal/ModalWindow";
import NewsArticle from "../news_articles/NewsArticle";
import NewsItem from "../../../../pages/news/news_items/NewsItem";

interface NewsState {
  news?: StorySquare[];
  activeNews: any;
}

interface NewsProps {
  api: ApiEngine;
}

export default class News extends React.Component<NewsProps, NewsState> {
  mount = false;

  constructor(_props: any) {
    super(_props);
    this.state = {
      news: undefined,
      activeNews: null,
    }
  }

  componentDidMount() {
    if (this.mount) return;
    this.mount = true;
    const me = this;
    const url = `/api/news/index`;
    me.props.api.asyncFetch(url, {}).then((_res) => {
      setTimeout(() => {
        me.setState({
          news: _res.news_articles
        });
      }, 400);
    });

  }

  render() {
    const me = this;
    return <div className={"news"} data-testid={"news-container"}>
      {
        me.state.news ?
          me.state.news.map((_new: StorySquare, _newsIndex: number) => {
            return <StorySquareComponent
                                        api={me.props.api}
                                        content={_new}
                                         onClick={() => {
                                           me.setState({
                                             activeNews: _new
                                           })
                                         }}
                                         key={`story-square-${_newsIndex}`}/>
          }) : <StorySquareSkeleton count={29} />
      }
      { me.state.activeNews && <ModalWindow
          onClick={() => {
            me.setState({activeNews: null})
          }}
      >

        <NewsArticle newsArticle={me.state.activeNews} api={me.props.api}/>
      </ModalWindow> }
    </div>
  }
}