import React from "react";
import "./style/trading-point.scss";
import TradingPointStats from "../stats/TradingPointStats";
import {ApiEngine} from "api-engine";
import KanBanContainer from "../../../components/kanban/KanBanContainer";
import TradingPointData from "../trading_poin_data/TradingPointData";
interface TradingPointComponentProps {
    tradingPoint: any;
    api: ApiEngine;
}

interface TradingPointComponentState {
    tabs: any[];
    activeTab: any;
}

export default class TradingPointComponent extends React.Component<TradingPointComponentProps, TradingPointComponentState> {
    constructor(props: TradingPointComponentProps) {
        super(props);
        const tabs = [
            {title: "Тикеты", realm: "demo", component: <KanBanContainer realm={"demo"} />},
            {title: "Заказы", realm: "demo", component: <KanBanContainer realm={"demo"} />},
            {title: "Сотрудники", realm: "demo", component: <KanBanContainer realm={"demo"} />},
        ]
        this.state = {
            tabs: tabs,
            activeTab: tabs[0]
        }
    }

    render() {
        const me = this;
        return <div className="trading-point">
            <h3>{me.props.tradingPoint.title}</h3>
            <TradingPointData tradingPoint={me.props.tradingPoint} api={me.props.api}/>
        </div>;
    }
}