import React from 'react';
import "../styles/total_style.scss";
import Main from "./layout/main/common/Main";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: `/*`,
        index: true,
        element: <Main />
    }

], {basename: process.env["PUBLIC_URL"]});

export default class App extends React.Component<any, any>{
  constructor(_props: any) {
    super(_props);
    this.state = {
      user: null
    }
  }
  render() {
    return <RouterProvider router={router} />
  }
}
