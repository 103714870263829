import React from "react";
import {Chart, PointElement, CategoryScale, LinearScale, LineElement, LineController, TimeSeriesScale} from "chart.js";
import 'chartjs-plugin-style';
import "./style/line-chart.scss";
import "chartjs-adapter-moment";
import moment from "moment/moment";
import "moment/locale/ru";

moment.locale('ru');

interface LineChartProps {
    width?: string;
    showX?: boolean,
    showY?: boolean,
    title?: string,
    data: any
}

const colors = ["#00AA52", "#1E88E5", "#FFC107", "#E53935", "#8E24AA", "#43A047", "#FB8C00", "#00ACC1", "#3949AB", "#C62828"];

export default class LineChart extends React.Component<LineChartProps, any> {
    chartRef = React.createRef<HTMLCanvasElement>();
    mount: Boolean = false
    componentDidMount() {
        if (this.mount) return;
        this.mount = true;
        const me = this;
        const plugin = {
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart: any, args: any, options: any) => {
                const {ctx} = chart;
                ctx.save();
                let _stroke = ctx.stroke;
                ctx.stroke = function() {
                    ctx.save();
                    ctx.shadowColor = ctx.strokeStyle;
                    ctx.shadowBlur = 5;
                    ctx.fillOpacity = 0;
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 4;
                    // ctx.fillStyle  = "#384146";
                    // ctx.fill();
                    _stroke.apply(this, arguments);
                    ctx.restore();
                };
                ctx.restore();
            }
        };

        Chart.register(LineController);
        Chart.register(CategoryScale);
        Chart.register(LinearScale);
        Chart.register(PointElement);
        Chart.register(LineElement);
        Chart.register(LineController);
        Chart.register(TimeSeriesScale);
        

        console.log(me.props.data);
        const chart = new Chart(
            me.chartRef!!.current!!,
            {
                options: {
                    devicePixelRatio: 1,
                    scales: {
                        x: {
                            type: "time",
                            border: {
                              display: !!me.props.showX
                            },
                            ticks: {
                              display: !!me.props.showX
                            },
                            grid: {
                                display: !!me.props.showX
                            }
                        },
                        y: {
                            border: {
                                display: !!me.props.showY
                            },
                            ticks: {
                                display: !!me.props.showY
                            },
                            grid: {
                                display: !!me.props.showY
                            }
                        }
                    },
                    backgroundColor: "transparent"
                },

                plugins: [plugin],
                type: 'line',
                data: {
                    // labels: me.props.data.map((row: any) => row.map((x: any) => moment(x.label))),

                    datasets: me.props.data.map((row: any, index: number) => {
                        return {
                            tension: 0.3,
                            borderColor: colors[index], 
                            label: me.props.title,
                            pointRadius: 0,
                            data: row.map((x: any) => {return {y: x.value, x:(moment(x.label))}})
                        }
                    })
                }
            });
    }
    render() {
        const me = this;
        return <div className={"line-chart"}
                    style={{display: "flex", minWidth: me.props.width , width: me.props.width}}
        >
            <canvas ref={me.chartRef} id={`chart`}></canvas>
        </div>
    }
}