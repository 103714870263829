import React from "react";
import "./style/demo.scss";
import Search from "../../components/searches/Search";
import LineChart from "../../components/charts/line_chart/LineChart";
import MainMetricWidget from "../../components/metric_widgets/MainMetricWidget";
import KanBan from "../../components/kanban/KanBan";
import {ApiContext} from "../../domains/api/contexts/ApiContext";
import KanBanContainer from "../../components/kanban/KanBanContainer";
const data = [
    { x: 2010, y: 10 },
    { x: 2011, y: 20 },
    { x: 2012, y: 15 },
    { x: 2013, y: 25 },
    { x: 2014, y: 22 },
    { x: 2015, y: 30 },
    { x: 2016, y: 28 },
];
export default class Demo extends React.Component<any, any> {
    render() {
        const me = this
        return <div className={"demo"}>
            <h1>Демо</h1>

            <div style={{margin: "20px"}}>
                <h2 style={{color: "var(--white)", margin: "10px"}}>Демо канбан</h2>
                <KanBanContainer realm={"demo"} />
            </div>
        </div>
    }
}