import React from "react";
import { ApiEngine } from "api-engine";
import "./style/documents.scss";

interface DocumentsPageProps {
    user: any;
    api: ApiEngine;
}   

interface DocumentsPageState {
    documents: any[];
    page: number;
    size: number;
}

export default class DocumentsPage extends React.Component<DocumentsPageProps, DocumentsPageState> {
    
    constructor(props: DocumentsPageProps) {
        super(props);
        this.state = {
            documents: [],
            page: 0,
            size: 20
        }
        this.getDocuments = this.getDocuments.bind(this);
        this.getMoreDocuments = this.getMoreDocuments.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getDocuments();
    }

    getDocuments() {
        const me = this;
        const url = `/api/documents?page=${me.state.page}&size=${me.state.size}`;
        me.props.api.asyncFetch(url, {}).then((res: any) => {
            me.setState({documents: res.documents});
        });
    }

    getMoreDocuments() {
        const me = this;
        me.setState({page: me.state.page + 1}, () => {
            const url = `/api/documents?page=${me.state.page}&size=${me.state.size}`;
            me.props.api.asyncFetch(url, {}).then((res: any) => {
                if (res.documents) {
                    me.setState({documents: [...me.state.documents, ...res.documents]});
                }
            });
        });
    }

    render() {
        const me = this;
        return <div className={"documents-page"}>
            <h1>Документы</h1>
            {
                <div ref={(ref) => {
                    if (!ref) return;
                    const observer = new IntersectionObserver(
                        ([entry]) => {
                            if (entry.isIntersecting) {
                                me.getMoreDocuments();
                            }
                        },
                        { threshold: 1.0 }
                    );
                    observer.observe(ref);
                }}>
                    {me.state.documents.map((document: any) => (
                        <div key={document.id} className={"document-item"}>
                            <i className={"fas fa-file-alt fa-3x"}>
                            </i>
                            <div className={"document-item-header"}>
                                <h2>{document.companyName}</h2>
                                <h3>{document.type}</h3>
                                <p>{document.date.toLocaleString()}</p>
                            </div>
                            <div className={"document-item-content"}>
                                <p>№&nbsp;{document.number}</p>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    }
}
