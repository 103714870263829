import React from "react";
import "./style/small-main-metric-widget.scss";

interface SmallMainMetricWidgetProps {
    metric: any
}

interface SmallMainMetricWidgetState {  
    dateInterval: string,
    dateStart: Date,
    dateEnd: Date
}

export default class SmallMainMetricWidget extends React.Component<SmallMainMetricWidgetProps, SmallMainMetricWidgetState> {
    render() {
        const me = this;
        return <div className={"small-main-metric-widget"}>
            <h1 className={"caption"}>{me.props.metric.title}</h1>
            <h2 className={"value"}>{me.props.metric.value.toLocaleString()}</h2>
        </div>;
    }
}