import React from "react";
import {ApiEngine} from "api-engine";
import KanBanContainer from "../../../components/kanban/KanBanContainer";
import "./style/trading_point_data.scss";
import TradingPointStats from "../stats/TradingPointStats";
import EmployeesPageContent from "../../../pages/employees/EmployeesPageContent";
import DocumentsPage from "../../../pages/documents/DocumentsPage";

interface TradingPointDataProps {
    tradingPoint: any;
    api: ApiEngine;
}

interface TradingPointDataState {
    tabs: any[];
    activeTab: any;
}

export default class TradingPointData extends React.Component<TradingPointDataProps, TradingPointDataState> {
    constructor(props: TradingPointDataProps) {
        super(props);
        const tabs = [
            {title: "Данные", component:<TradingPointStats tradingPoint={this.props.tradingPoint} api={this.props.api}/> },
            {title: "Тикеты", component: <KanBanContainer realm={"demo"} />},
            {title: "Документы", component: <DocumentsPage user={null} api={this.props.api} />},
            {title: "Сотрудники", component: <EmployeesPageContent api={this.props.api}
                                                                   hideTradingPointsChooser={true}
                                                                   tradingPoint={this.props.tradingPoint}
                />},
        ]
        this.state = {
            tabs: tabs,
            activeTab: tabs[0]
        }
    }
    render() {
        return <div className="trading-point-data">
            <div className="tabs">
                {this.state.tabs.map((tab: any) => {
                    return <div className={`tab ${this.state.activeTab?.title === tab.title ? "tab-active" : ""}`} onClick={() => this.setState({activeTab: tab})}>
                    <h4>{tab.title}</h4>
                    </div>
                })}
            </div>

            <div className="content" key={this.state.activeTab?.title}>
                {this.state.activeTab?.component}
            </div>
        </div>;
    }
}