import React from "react";
import {ApiContext} from "../../domains/api/contexts/ApiContext";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import TradingPointsPageContent from "./TradingPointsPageContent";

export default class TradingPointsPage extends React.Component<any, any> {
    render() {
        return <ApiProvider>
            <ApiContext.Consumer>
                {
                    ({api, user}) => {
                        if (user === null) return <p>Вы не авторизованы</p>
                        return <TradingPointsPageContent api={api!!} />
                    }
                }
            </ApiContext.Consumer>
        </ApiProvider>
    }
}