import React from "react";
import {ApiEngine} from "api-engine";
import "./style/trading-points-page.scss";
import TradingPointComponent from "../../domains/trading_points/common/TradingPointComponent";

interface TradingPointsPageContentProps {
    api: ApiEngine
}

interface TradingPointsPageContentState {
    tradingPoints: any[];
    showTradingPoints: any[];
    filter: string;
    activeTradingPoint: any;
}

export default class TradingPointsPageContent extends React.Component<TradingPointsPageContentProps, TradingPointsPageContentState> {

    constructor(props: TradingPointsPageContentProps) {
        super(props);
        this.state = {
            tradingPoints: [],
            showTradingPoints: [],
            filter: "",
            activeTradingPoint: null
        }
        this.getTradingPoints = this.getTradingPoints.bind(this);
    }

    componentDidMount() {
        this.getTradingPoints();
    }

    getTradingPoints() {
        const url = "/api/companies";
        this.props.api.asyncFetch(url, {}).then((response) => {
            this.setState({ tradingPoints: response.companies, showTradingPoints: response.companies });
        });
    }

    render() {
        const me = this;
        return <div className={"trading-points-page-content"}>
            <h1>Торговые предприятия</h1>
            <div className="trading-points-list-container">
                <div className={"trading-point-chooser"}>
                    <div className={"search-bar"}>
                        <input type="text" placeholder="Фильтр" value={me.state.filter} onChange={(e) => me.setState({
                            filter: e.target.value,
                            showTradingPoints: me.state.tradingPoints.filter((tradingPoint) => tradingPoint.title.toLowerCase().includes(e.target.value.toLowerCase()))
                        })}/>
                    </div>
                    <div className="trading-points-list">
                        {me.state.showTradingPoints.map((tradingPoint) => {
                            return <div
                                className={`trading-point-button ${me.state.activeTradingPoint?.id === tradingPoint.id ? "trading-point-active" : ""}`}
                                key={tradingPoint.id} onClick={() => {
                                me.setState({activeTradingPoint: tradingPoint});
                            }}>
                                <div className={"metrics"}>
                                    <h5>{tradingPoint.title}</h5>
                                    <p className={"target-plan"}><i className={"fal fa-ruble-sign"}/>{tradingPoint.targetPlan ? tradingPoint.targetPlan.toLocaleString() : "-"}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                {me.state.activeTradingPoint &&
                    <TradingPointComponent
                        key={me.state.activeTradingPoint.id}
                        tradingPoint={me.state.activeTradingPoint}
                        api={me.props.api}/>
                }
            </div>
        </div>
    }
}