import React from "react";
import "./style/trading-point-stats.scss";
import MainMetricWidget from "../../../components/metric_widgets/MainMetricWidget";
import { ApiEngine } from "api-engine";


function generateData(min: number = 0, max: number = 1000) {
    return [
        { x: "Январь", y: min + Math.random() * max },
        { x: "Февраль", y: min + Math.random() * max },
        { x: "Март", y: min + Math.random() * max },
        { x: "Апрель", y: min + Math.random() * max },
        { x: "Май", y: min + Math.random() * max },
        { x: "Июнь", y: min + Math.random() * max },
        { x: "Июль", y: min + Math.random() * max },
        { x: "Август", y: min + Math.random() * max },
        { x: "Сентябрь", y: min + Math.random() * max },
        { x: "Октябрь", y: min + Math.random() * max },
    ];
}

const data = [
    { title: "Выручка", data: generateData(0, 1000000), value: Math.round(Math.random() * 1000000), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "Кол-во чеков", data: generateData(0, 1000), value: Math.round(Math.random() * 1000), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "Средний чек", data: generateData(0, 400), value: Math.round(Math.random() * 400), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "Себестоимость в рублях", data: generateData(0, 5000), value: Math.round(Math.random() * 5000), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "Себестоимость в %", data: generateData(0, 100), value: Math.round(Math.random() * 100), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "Сумма скидки", data: generateData(0, 10000), value: Math.round(Math.random() * 10000), prevDelta: 12 + Math.round(Math.random() * 10) },
    { title: "% скидки", data: generateData(0, 100), value: Math.round(Math.random() * 100) },
]

interface TradingPointStatsProps {
    tradingPoint: any;
    api: ApiEngine;
}

interface TradingPointStatsState {
    search: string;
    metrics: any[];
}

export default class TradingPointStats extends React.Component<TradingPointStatsProps, TradingPointStatsState> {

    constructor(props: TradingPointStatsProps) {
        super(props);
        this.state = {
            search: "",
            metrics: []
        }
    }

    componentDidMount() {
        const me = this;
        me.props.api.asyncFetch(`/api/companies/${me.props.tradingPoint.id}/metrics`, {}).then((res: any) => {
            const url = `/api/companies/${me.props.tradingPoint.id}/metrics/get`;
            me.setState({
                metrics: res.metricsList.map((metric: any) => {metric.url = url + "?metric=" + encodeURI(metric.title); console.log(metric); return metric; }),
            })
            console.log(res);
        });
    }

    render() {
        const me = this;
        return <div className={"trading-points-stats"}>
            <div className={"search-zone"}>
                <input placeholder={"Поиск"} value={me.state.search} onChange={(e) => me.setState({search: e.target.value})}    />
            </div>
            <div className={"stats"} style={{overflowX: "scroll", width: "100%"}}>
                {
                    me.state.metrics.map((metric, metricIndex) => {
                    // data.map((metric, metricIndex) => {
                        if (metric.title.toLowerCase().includes(me.state.search.toLowerCase())) {
                            return <MainMetricWidget api={me.props.api} metric={metric}/>
                        }
                    })
                }
            </div>
        </div>;
    }
}