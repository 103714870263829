import React from "react";
import { DayPicker } from "react-day-picker";
import { ru } from "react-day-picker/locale";
import "react-day-picker/style.css";
import "./style/calendar_container.scss";
import ChatVerticalBar from "../../../../domains/chat/layout/chat_vertical_bar/ChatVerticalBar";
import {ApiEngine} from "api-engine";

interface StatusColumnProps {
    user: any;
    api: ApiEngine;
}

interface statusColumnState {
    calendarOpen: boolean
}

export default class StatusColumn extends React.Component<StatusColumnProps, statusColumnState> {
    constructor(props: StatusColumnProps) {
        super(props);
        this.state = {
            calendarOpen: false
        }
    }

    render() {
        const me = this;
        return <div className={"status-column"}
                    style={{maxWidth: me.state.calendarOpen ? "350px" : undefined}}
        >
            <div className={"calendar-container"}
                 style={{display: me.state.calendarOpen ? "flex" : undefined}}
            >
                <DayPicker
                    dir="ltr"
                    mode="single"
                    numberOfMonths={1}
                    timeZone="Europe/Moscow"
                    showOutsideDays
                    locale={ru}
                />
            </div>
            <button className={"show-calendar-button"}
                onClick={() => {
                    me.setState({calendarOpen: !me.state.calendarOpen});
                }}
            >
                <i className={"fas fa-2x fa-calendar"} />
            </button>
            <ChatVerticalBar user={me.props.user}
                             api={me.props.api!!} />
        </div>

    }
}
