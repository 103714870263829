import React from "react";
import { ApiContext } from "../contexts/ApiContext";
import {ApiEngine, SessionContainer} from "api-engine";
import User from "../../actors/users/User";
interface ApiProviderProps {
  children: any
}
interface ApiProviderState {
  user: User | null,
  setUser: any,
  api: ApiEngine
}
export default class ApiProvider extends React.Component<ApiProviderProps, ApiProviderState> {
  init: boolean = false;

  constructor(_props: ApiProviderProps) {
    super(_props);
    localStorage.setItem("csrf", "no-csrf")
    let sessionContainer = new SessionContainer<User>(User, "/api/me");
    let baseUrl = "https://onerm.fun";
    // let baseUrl = "http://localhost:8080";
    let api = new ApiEngine(baseUrl, 0, sessionContainer);
    api.startQueue();

    this.state = {
      user: null,
      setUser: this.setUser.bind(this),
      api: api
    };
  }

  componentDidMount() {
    const me = this;
    if (me.init) return;
    me.init = true;
    me.state.api.sessionContainer.checkUser().then((_user: any) => {
      me.setState({user: _user})
    })
  }

  setUser(_user: User) {
    this.setState({user: _user})
  }

  render() {
    return (
      <ApiContext.Provider value={this.state}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}