import React from "react";
import "./style/chat-opening-button.scss";

export default class ChatOpeningButtonSkeleton extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            chatIsOpened: false
        };
    }

    render() {
        return <div className={"chat-opening-button-container"}
                    style={{justifyContent: "center", marginRight: "23px"}} onClick={() => {}}
        >
            <div className={"chat-opening-button-skeleton"}></div>
            <div className={"chat-opening-button-title-skeleton"}></div>
        </div>
    }
}
