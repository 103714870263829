import React from "react";
import "./style/logout.scss";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import { ApiContext } from "../../domains/api/contexts/ApiContext";

interface LogoutState {
    text: string;
    dots: string;
}

interface LogoutProps {
}

export default class Logout extends React.Component<LogoutProps, LogoutState> {
    blinkingDotsTimeout: any;
    constructor(props: any) {
        super(props);
        this.state = {
            text: "Производится выход",
            dots: "..."
        }
        this.countNumberOfDots = this.countNumberOfDots.bind(this);
    }

    countNumberOfDots() {
        const me = this;
        clearTimeout(me.blinkingDotsTimeout);
        const text = me.state.text;
        const dots = me.state.dots;
        const numberOfDots = dots.length;
        const newNumberOfDots = numberOfDots + 1;
        let newDots = dots.slice(0, newNumberOfDots) + ".";
        if (newDots.length >= 7) { newDots = "";}
        me.setState({
            text: text,
            dots: newDots
        }, () => {
            setTimeout(me.countNumberOfDots, 200);
        });
    }

    componentDidMount() {
        const me = this;
        this.countNumberOfDots();
        setTimeout(() => {
            localStorage.removeItem('jwt');
            document.location.href="/";
        }, 3000)
    }

    componentWillUnmount() {
        clearTimeout(this.blinkingDotsTimeout);
    }

    render() {
        return <ApiProvider>
            <ApiContext.Consumer>
                {
                    ({api, user}) => {
                        if (user) return <div className={"logout"}>
                            <h1>Производится выход{this.state.dots}</h1>
                        </div>
                        return <></>
                    }
                }
            </ApiContext.Consumer>
        </ApiProvider>


    }
}