import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {ApiEngine} from "api-engine";
import "./style/kanban.scss";

interface KanBanProps {
    realm: string;
    api: ApiEngine;
}

interface KanBanState {
    stages: string[];
    tickets: { [key: string]: any[] };
    isLoading: boolean;
}

class KanBan extends React.Component<KanBanProps, KanBanState> {
    dataFetchingTimeout: any = null;
    constructor(props: KanBanProps) {
        super(props);
        this.state = {
            stages: [],
            tickets: {},
            isLoading: true
        };

        this.fetchBoardData = this.fetchBoardData.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        this.fetchBoardData();
    }

    fetchBoardData = async () => {
        const me = this;
        const { realm, api } = this.props;
        const getStagesUrl = `/api/tickets/${realm}/stages`;

        const stagesResponse = await api.asyncFetch(getStagesUrl, {});
        this.setState({ stages: stagesResponse.stages }, async () => {
            const ticketPromises = stagesResponse.stages.map(async (stage: string) => {
                const getTicketsUrl = `/api/tickets/${realm}/index_by_stage/${stage}`;
                const ticketsResponse = await api.asyncFetch(getTicketsUrl, {});
                return { [stage]: ticketsResponse.tickets };
            });

            const ticketsResults = await Promise.all(ticketPromises);
            const tickets = ticketsResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});

            this.setState({ tickets, isLoading: false }, () => {
                setTimeout(me.fetchBoardData, 15000);
            });
        });

    };

    onDragStart = (result: any) => {
        if (this.dataFetchingTimeout) clearTimeout(this.dataFetchingTimeout)
        console.log('Drag started:', result);
    };

    onDragEnd = (result: any) => {
        const me = this;
        const { destination, source, draggableId } = result;
        if (!destination) {
            this.fetchBoardData();
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const sourceStage = source.droppableId;
        const destStage = destination.droppableId;

        const newTickets = { ...this.state.tickets };
        const sourceTickets = newTickets[sourceStage];
        const movedTicket = sourceTickets.splice(source.index, 1);

        if (sourceStage !== destStage) {
            const destTickets = newTickets[destStage] ? newTickets[destStage] : [];
            destTickets.push(movedTicket[0]);
            newTickets[sourceStage] = sourceTickets;
            newTickets[destStage] = destTickets;
        }

        this.setState({ tickets: newTickets }, () => {
            const body = JSON.stringify({
                ticketId: draggableId,
                stage: destination.droppableId
            });
            me.props.api.asyncFetch(`/api/tickets/${me.props.realm}/tickets/${draggableId}/update_stage`, {
                method: 'POST',
                body: body
            }).then(me.fetchBoardData)
        });

    };

    render() {
        if (this.state.isLoading) {
            return <div style={{color: "var(--white)"}}>Загрузка...</div>;
        }

        return (
            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                <div className={"kanban-container"}>
                    { this.state.stages.map((stage) => (
                        <div key={stage} className={"kanban-stage"}>
                            <h3>{stage}</h3>
                            <Droppable droppableId={stage}>
                                {(provided) => (
                                    <div
                                        className={"droppable-zone"}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {this.state.tickets[stage]?.sort((a, b) => a.title.localeCompare(b.title)).map((ticket) => (
                                            <Draggable key={`${stage}-${ticket.id}`} draggableId={ticket.id} index={ticket.id}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={"kanban-dragger"}

                                                    >
                                                        {ticket.title}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    ))}
                </div>
            </DragDropContext>
        );
    }
}

export default KanBan;







