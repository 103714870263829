import React from "react";
import "./style/main_metrics.scss";
import Marquee from "react-fast-marquee";
import SmallMainMetricWidget from "../../../components/metric_widgets/small_main_metric_widget/SmallMainMetricWidget";

const metrics = [
    {value: 2700, title: "Капучино"},
    {value: 700, title: "Эспрессо"},
    {value: 270, title: "Латте"},
    {value: 32, title: "Раф"},
    {value: 32, title: "Два"},
    {value: 32, title: "Три"},
    {value: 12700, title: "3 в 1"},
]
export default class MainMetricsPart extends React.Component<any, any> {
    render() {
        return <Marquee
            pauseOnHover
            gradient={window.innerWidth < 500 ? false : true}
            gradientColor={"var(--dark-black)"}
            className={"main-metrics"}
        >
        {/*<div className={"main-metrics"}>*/}
            { metrics.map((_metric, _metricIndex) => {
                return <span style={{display: "block", paddingLeft: "30px"}}>
                    <SmallMainMetricWidget  key={`metric_${_metricIndex}`}
                                            metric={_metric} />
                </span>
            })}
        {/*</div>*/}
        </Marquee>
    }
}