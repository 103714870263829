import React from "react";
import "./style/main_metric_widget.scss";
import LineChart from "../charts/line_chart/LineChart";
import ModalWindow from "../../layout/modal/ModalWindow";
import FullMetricWidget from "./full_metric_widget/FullMetricWidget";
import { ApiEngine } from "api-engine";

interface MainMetricWidgetProps {
    fullScreenView?: boolean,
    metric: any,
    api: ApiEngine
}

interface MainMetricWidgetState {
    fullScreenView: boolean,
    prevDelta: any,
    title: string,
    value: any,
    data: any[],
}

export default class MainMetricWidget extends React.Component<MainMetricWidgetProps, MainMetricWidgetState> {
    constructor(props: MainMetricWidgetProps) {
        super(props);
        this.state = {
            fullScreenView: false,
            prevDelta: this.props.metric.prevDelta,
            title: this.props.metric.title,
            value: this.props.metric.value,
            data: this.props.metric.data,
        }
    }

    componentDidMount() {
        const me = this;
        me.props.api.asyncFetch(me.props.metric.url, {}).then((res: any) => {
            let prevValue = Math.round((res.value[res.value.length - 1].value  - res.value[res.value.length - 2].value) / res.value[res.value.length - 2].value * 100);
            let currentValue = res.value[res.value.length - 1].value;
            me.setState({data: res.value, value: currentValue, prevDelta: prevValue});
        });
    }

    render() {
        const me = this;
        return <>
            <div className={"main-metric-widget"}
                    onClick={() => {
                        if (!me.state.data) return;
                        me.setState({fullScreenView: !me.state.fullScreenView})}
                    }
                    style={me.state.data ? {background: "var(--blue-gray)"} : {}}
            >
                <div className={"content"}>
                    <p className={"value"}>{ me.state.value ? me.state.value.toLocaleString() :
                        <div className={"chart-loading-skeleton"}  style={{width: "40px", height: "60px"}}><i className={"fal fa-hourglass"}></i></div>
                    }</p>
                    <p className={"caption"}>{me.state.title}</p>
                    { (me.state.prevDelta !== 0) &&
                        <p className={"prev-delta"}
                           style={{color: me.state.prevDelta > 0 ? "var(--green-500)" : "var(--red-500)"}}>{me.state.prevDelta > 0 ? "+" : ""}{me.state.prevDelta}%</p>
                    }
                </div>
                { me.state.data ?
                    <LineChart data={[me.state.data]} width={"200px"} title={me.state.title} /> :
                    <div className={"chart-loading-skeleton"}><i className={"fal fa-line-chart"}></i></div>
                }
                {me.state.fullScreenView &&
                <ModalWindow onClick={() => {
                    me.setState({fullScreenView: false})
                }}>
                    <div className={"full-screen-view-content"} onClick={(e) => {
                        e.stopPropagation()}
                    }>
                        <FullMetricWidget metric={{
                            title: me.state.title,
                            value: me.state.value,
                            data: me.state.data
                        }} />
                    </div>
                    </ModalWindow>
                }
            </div>
        </>
    }
}