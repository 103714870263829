import React from "react";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import { ApiContext } from "../../domains/api/contexts/ApiContext";
import DocumentsPage from "./DocumentsPage";

export default class Documents extends React.Component<any, any> {
    render() {
        return <ApiProvider>
            <ApiContext.Consumer>
                {
                    ({user, api}) => {
                        if (!user) return <h3>Вы не авторизованы</h3>;
                        return <DocumentsPage user={user!!} api={api!!} />
                    }
                }
            </ApiContext.Consumer>
        </ApiProvider>
    }
}