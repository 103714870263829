import React from "react";
import "./style/full-metric-widget.scss";
import LineChart from "../../charts/line_chart/LineChart";

interface FullMetricWidgetProps {
    metric: any,
}

interface FullMetricWidgetState {
    dateInterval: DateInterval,
    dateStart: Date,
    dateEnd: Date
}

interface DateInterval {
    title: string,
    value: string,
    dateStart: Date,
    dateEnd: Date
}


const possibleDateIntervals = [{
    title:"Сегодня",
    value: "1d",
    dateStart: new Date().setHours(0, 0, 0, 0),
    dateEnd: new Date()
}, {
    title:"неделя",
    value: "7d",
    dateStart: new Date().setDate(new Date().getDate() - 7),
    dateEnd: new Date()
}, {
    title:"месяц",
    value: "30d",
    dateStart: new Date().setDate(new Date().getDate() - 30),
    dateEnd: new Date()
}, {
    title:"1кв",
    value: "90d",
    dateStart: new Date().setMonth(new Date().getMonth() - 3),
    dateEnd: new Date()
}, {
    title:"2кв",
    value: "180d",
    dateStart: new Date().setMonth(new Date().getMonth() - 6),
    dateEnd: new Date()
}, {
    title:"3кв",
    value: "270d",
    dateStart: new Date().setMonth(new Date().getMonth() - 9),
    dateEnd: new Date()
}, {
    title:"4кв",
    value: "365d",
    dateStart: new Date().setMonth(new Date().getMonth() - 12),
    dateEnd: new Date()
},
{
    title:"год",
    value: "365d",
    dateStart: new Date().setFullYear(new Date().getFullYear() - 1, 0, 1),
    dateEnd: new Date().setFullYear(new Date().getFullYear(), 0, 1)
}] as unknown as DateInterval[];

export default class FullMetricWidget extends React.Component<FullMetricWidgetProps, FullMetricWidgetState> {
    constructor(props: FullMetricWidgetProps) {
        super(props);
        this.state = {
            dateInterval: possibleDateIntervals[7],
            dateStart: possibleDateIntervals[7].dateStart,
            dateEnd: possibleDateIntervals[7].dateEnd
        }
    }

    render() {
        const me = this;
        return <div className={"full-metric-widget"}>
            <h1 className={"caption"}>{me.props.metric.title}</h1>
            <h2 className={"value"}>{me.props.metric.value.toLocaleString()}</h2>
            <div className={"date-interval"}>
                {possibleDateIntervals.map((interval, index) => (
                    <button type="button"
                            key={index}
                            onClick={() => me.setState({dateInterval: interval,
                                                            dateStart: interval.dateStart,
                                                            dateEnd: interval.dateEnd
                                                        })}
                            className={me.state.dateInterval.title === interval.title ? "active" : ""}
                    >
                        {interval.title}
                    </button>
                ))}
                <input type={"datetime-local"}
                       value={me.state.dateStart.toLocaleString()}
                       onChange={(e) => me.setState({dateStart: new Date(e.target.value)})}
                />
                <input type={"datetime-local"}
                       value={me.state.dateEnd.toLocaleString()}
                       onChange={(e) => me.setState({dateEnd: new Date(e.target.value)})}
                />
            </div>
            <LineChart key={`${me.state.dateStart}-${me.state.dateEnd}`} data={[me.props.metric.data, me.props.metric.data.map((x: any) => {
                return  {
                    label: x.label,
                    value: x.value * (1  +  0.02 * (Math.random() - 0.2))
                }
            } )]} width={"100%"} showX={true} showY={true} />
        </div>
    }
}